import React, { useState, useEffect, useRef } from "react";
import { ImPlay2 } from "react-icons/im";
import { triggerCustomGTM } from "../../utils/helper";

function CustomVideo({
    videoId,
    videoThumbnail,
    videoIdParam,
    dontAllowPlay,
    onPlay,
    insideSlider,
    altText,
    customTagText,
    videoName,
}) {
    const [startVideo, setStartVideo] = useState(false);
    const playerRef = useRef();
    const imgRef = useRef();

    // Load Vimeo SDK
    useEffect(() => {
        const existingScript = document.getElementById("vimeo-sdk");

        if (!existingScript) {
            const script = document.createElement("script");
            script.src = "https://player.vimeo.com/api/player.js";
            script.id = "vimeo-sdk"; // give it an id
            document.body.appendChild(script);
        }
    }, []);

    // Preload video metadata
    useEffect(() => {
        let vimeoPlayer;

        if (startVideo && window.Vimeo && playerRef.current) {
            vimeoPlayer = new window.Vimeo.Player(playerRef.current);

            vimeoPlayer.on("loaded", function () {
                setTimeout(() => {
                    vimeoPlayer.play().catch((error) => {
                        console.error("Playback error:", error);
                    });
                }, 700);
            });
        }

        return () => {
            if (vimeoPlayer) {
                vimeoPlayer.unload();
            }
        };
    }, [startVideo, videoId, videoIdParam]);

    // Lazy load handler
    useEffect(() => {
        let observer;

        if (startVideo && playerRef.current) {
            observer = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting) {
                    const iframe = playerRef.current.querySelector("iframe");
                    if (iframe) {
                        iframe.src += "&autoplay=1";
                    }
                    observer.unobserve(playerRef.current);
                }
            });

            observer.observe(playerRef.current);
        }

        return () => observer && observer.disconnect();
    }, [startVideo]);

    const handleStartClick = (e) => {
        e.preventDefault();

        if (!dontAllowPlay) {
            !insideSlider && setStartVideo(true);
            onPlay && onPlay();
        }

        if (customTagText) {
            triggerCustomGTM({
                event: "gtm.click",
                customTag: customTagText,
                videoName: videoName,
            });
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter" || e.key === " ") handleStartClick(e);
    };

    return (
        <div
            className="card-video bg-cover main-video"
            onClick={handleStartClick}
            onKeyDown={handleKeyDown}
            tabIndex="0"
            role="button"
            aria-label={`Play ${videoName}`}
        >
            <img
                ref={imgRef}
                src={videoThumbnail}
                alt={altText}
                style={{ display: !startVideo ? "block" : "none" }}
            />

            <div
                ref={playerRef}
                style={{ display: startVideo ? "inline" : "none" }}
            >
                <iframe
                    src={`https://player.vimeo.com/video/${videoId}?h=${videoIdParam}`}
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    allowFullScreen={true}
                    allow="autoplay"
                    title={`Vimeo Video ${videoName}`} // Added for accessibility
                />
            </div>

            <button
                tabIndex={startVideo ? "0" : "-1"}
                style={{ display: !startVideo ? "block" : "none" }}
                className="play-btn"
                aria-hidden="true"
            >
                <ImPlay2 />
            </button>
        </div>
    );
}

export default CustomVideo;
